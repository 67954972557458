export default class ErpProductEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_product_id") ? (elem.querySelector("#erp_product_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#erp_product_name") as HTMLInputElement).value,
            productNumber: (elem.querySelector("#erp_product_productNumber") as HTMLInputElement).value,
            categoryId: elem.querySelector("#erp_product_category_id") ? (elem.querySelector("#erp_product_category_id option:checked") as HTMLInputElement).value : null,

            customFields: {
                erp_product_requires_serial:   (elem.querySelector("#erp_product_requires_serial") as HTMLInputElement).checked
            },
            price: [
                {
                    gross: parseFloat((elem.querySelector("#erp_product_price") as HTMLInputElement).value),
                    net: parseFloat((elem.querySelector("#erp_product_price_net") as HTMLInputElement).value),
                    currencyId: (elem.querySelector("#erp_product_price_currencyId") as HTMLInputElement).value,
                    linked: true
                }
            ],
            purchasePrices: [
                {
                    net: parseFloat((elem.querySelector("#erp_product_purchasing_price_net") as HTMLInputElement).value) || 0,
                    gross: parseFloat((elem.querySelector("#erp_product_purchasing_price") as HTMLInputElement).value) || 0,
                    currencyId: (elem.querySelector("#erp_product_purchasing_price_currencyId") as HTMLInputElement).value || (elem.querySelector("#erp_product_price_currencyId") as HTMLInputElement).value,
                    linked: true
                }
            ],
            taxId: elem.querySelector("#erp_product_taxId") ? (elem.querySelector("#erp_product_taxId option:checked") as HTMLInputElement).value : null,


            width: parseInt((elem.querySelector("#erp_product_width") as HTMLInputElement).value),
            height: parseInt((elem.querySelector("#erp_product_height") as HTMLInputElement).value),
            length: parseInt((elem.querySelector("#erp_product_length") as HTMLInputElement).value),
            weight: parseInt((elem.querySelector("#erp_product_weight") as HTMLInputElement).value),


            ean: (elem.querySelector("#erp_product_ean") as HTMLInputElement).value,
            manufacturerName: (elem.querySelector("#erp_product_manufacturer") as HTMLInputElement).value,
            manufacturerNumber: (elem.querySelector("#erp_product_manufacturerNumber") as HTMLInputElement).value,
            description: (elem.querySelector("#erp_product_description") as HTMLInputElement).value,
            manufacturerId: null,
            stock: 0
        }
    }
}